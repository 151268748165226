export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Scalar for BigDecimal */
  BigDecimal: { input: any; output: any };
  /** Scalar for BigInteger */
  BigInteger: { input: any; output: any };
  /** Scalar for Date */
  Date: { input: any; output: any };
  /** Scalar for DateTime */
  DateTime: { input: any; output: any };
  /** Scalar for Void */
  Void: { input: any; output: any };
};

export enum AbandonReason {
  AbandonedByDistributor = "ABANDONED_BY_DISTRIBUTOR",
  AbandonedByInsurer = "ABANDONED_BY_INSURER",
  AbandonedByUnknown = "ABANDONED_BY_UNKNOWN",
  AbandonedByUser = "ABANDONED_BY_USER"
}

export type AccountingNote = {
  __typename?: "AccountingNote";
  audit?: Maybe<Audit>;
  /** ISO-8601 */
  createdAt?: Maybe<Scalars["String"]["output"]>;
  csvFileRef?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  distinctionKey?: Maybe<Scalars["String"]["output"]>;
  finalized: Scalars["Boolean"]["output"];
  /** ISO-8601 */
  finalizedAt?: Maybe<Scalars["String"]["output"]>;
  /** ISO-8601 */
  from?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  insurlet?: Maybe<Insurlet>;
  invoiceNumber?: Maybe<Scalars["String"]["output"]>;
  noteType?: Maybe<AccountingNoteType>;
  pdfFileRef?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<AccountingNoteStatus>;
  tenantId?: Maybe<Scalars["String"]["output"]>;
  /** ISO-8601 */
  to?: Maybe<Scalars["String"]["output"]>;
  totalAmount?: Maybe<Scalars["Float"]["output"]>;
  totalRecords: Scalars["BigInteger"]["output"];
};

export enum AccountingNoteStatus {
  Due = "DUE",
  Paid = "PAID"
}

export enum AccountingNoteType {
  OurInvoice = "OUR_INVOICE",
  TheirInvoice = "THEIR_INVOICE"
}

export enum AddressType {
  Business = "BUSINESS",
  Contact = "CONTACT",
  Home = "HOME"
}

export enum ArrayOperationType {
  Contains = "CONTAINS",
  NotContains = "NOT_CONTAINS"
}

export type Asset = {
  attachments?: Maybe<Array<Maybe<AttachmentQl>>>;
  currency?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  identifier?: Maybe<Scalars["String"]["output"]>;
  marketValue?: Maybe<Scalars["Float"]["output"]>;
  metadata?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<AssetType>;
};

export enum AssetType {
  Bicycle = "BICYCLE",
  Cyber = "CYBER",
  PortableDevice = "PORTABLE_DEVICE",
  Reservation = "RESERVATION",
  Residence = "RESIDENCE",
  Vehicle = "VEHICLE"
}

export type AttachmentQl = {
  __typename?: "AttachmentQL";
  assetType?: Maybe<AssetType>;
  description?: Maybe<Scalars["String"]["output"]>;
  fileId?: Maybe<Scalars["String"]["output"]>;
  fileType?: Maybe<FileType>;
  filename?: Maybe<Scalars["String"]["output"]>;
  friendlyName?: Maybe<Scalars["String"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
  required?: Maybe<Scalars["Boolean"]["output"]>;
  target?: Maybe<AttachmentTarget>;
};

export type AttachmentQlInput = {
  assetType?: InputMaybe<AssetType>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  fileId?: InputMaybe<Scalars["String"]["input"]>;
  fileType?: InputMaybe<FileType>;
  filename?: InputMaybe<Scalars["String"]["input"]>;
  friendlyName?: InputMaybe<Scalars["String"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  required?: InputMaybe<Scalars["Boolean"]["input"]>;
  target?: InputMaybe<AttachmentTarget>;
};

export enum AttachmentTarget {
  Asset = "ASSET",
  OtherInsured = "OTHER_INSURED",
  PolicyHolder = "POLICY_HOLDER",
  PrimaryInsured = "PRIMARY_INSURED"
}

export type Audit = {
  __typename?: "Audit";
  /** The timestamp of the creation (ISO-8601) */
  createdAt?: Maybe<Scalars["String"]["output"]>;
  /** The user who created the entity */
  createdBy?: Maybe<Scalars["String"]["output"]>;
  createdOrderId?: Maybe<Scalars["String"]["output"]>;
  /** The timestamp of the modification (ISO-8601) */
  modifiedAt?: Maybe<Scalars["String"]["output"]>;
  /** The last user who modified the entity */
  modifiedBy?: Maybe<Scalars["String"]["output"]>;
  modifiedOrderId?: Maybe<Scalars["String"]["output"]>;
};

export type AuthorizedResource = {
  __typename?: "AuthorizedResource";
  resource?: Maybe<Scalars["String"]["output"]>;
  roles?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type BicycleAsset = Asset & {
  __typename?: "BicycleAsset";
  /** attachments */
  attachments?: Maybe<Array<Maybe<AttachmentQl>>>;
  currency?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  identifier?: Maybe<Scalars["String"]["output"]>;
  /** manufacturer */
  manufacturer?: Maybe<Scalars["String"]["output"]>;
  marketValue?: Maybe<Scalars["Float"]["output"]>;
  metadata?: Maybe<Scalars["String"]["output"]>;
  /** model */
  model?: Maybe<Scalars["String"]["output"]>;
  /** serialNumber */
  serialNumber?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<AssetType>;
};

export type BicycleAssetInput = {
  /** attachments */
  attachments?: InputMaybe<Array<InputMaybe<AttachmentQlInput>>>;
  /** The ISO 4217 symbol of the currency. */
  currency?: InputMaybe<Scalars["String"]["input"]>;
  /** description */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** identifier */
  identifier?: InputMaybe<Scalars["String"]["input"]>;
  /** manufacturer */
  manufacturer?: InputMaybe<Scalars["String"]["input"]>;
  /** marketValue */
  marketValue?: InputMaybe<Scalars["Float"]["input"]>;
  /** metadata */
  metadata?: InputMaybe<Scalars["String"]["input"]>;
  /** model */
  model?: InputMaybe<Scalars["String"]["input"]>;
  /** serialNumber */
  serialNumber?: InputMaybe<Scalars["String"]["input"]>;
  /** type */
  type?: InputMaybe<AssetType>;
};

export type CancellationEndorsement = {
  __typename?: "CancellationEndorsement";
  caseRef?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  data?: Maybe<Scalars["String"]["output"]>;
  endorsementId?: Maybe<Scalars["String"]["output"]>;
  refundedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** ISO-8601 */
  resolutionDate?: Maybe<Scalars["String"]["output"]>;
};

export type CardDetails = {
  __typename?: "CardDetails";
  country?: Maybe<Scalars["String"]["output"]>;
  last4?: Maybe<Scalars["String"]["output"]>;
  masked?: Maybe<Scalars["String"]["output"]>;
  network?: Maybe<Scalars["String"]["output"]>;
  threeDSecure?: Maybe<Scalars["Boolean"]["output"]>;
};

export type Channel = {
  __typename?: "Channel";
  active?: Maybe<Scalars["Boolean"]["output"]>;
  audit?: Maybe<Audit>;
  homeUrl?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  type?: Maybe<ChannelType>;
};

export enum ChannelType {
  Android = "ANDROID",
  Ios = "IOS",
  Web = "WEB"
}

export type Claim = {
  __typename?: "Claim";
  assets?: Maybe<Array<Maybe<Asset>>>;
  audit?: Maybe<Audit>;
  claimRef?: Maybe<Scalars["String"]["output"]>;
  coDistributor?: Maybe<Distributor>;
  /** ISO-8601 */
  createdDate?: Maybe<Scalars["String"]["output"]>;
  data?: Maybe<Scalars["String"]["output"]>;
  /** ISO-8601 */
  dateOfLoss?: Maybe<Scalars["String"]["output"]>;
  distributor?: Maybe<Distributor>;
  events?: Maybe<Array<Maybe<ClaimEvent>>>;
  id?: Maybe<Scalars["String"]["output"]>;
  insured?: Maybe<Person>;
  insurer?: Maybe<Insurer>;
  insurlet?: Maybe<Insurlet>;
  insurletVersion?: Maybe<Scalars["String"]["output"]>;
  policy?: Maybe<Policy>;
  policyHolder?: Maybe<Person>;
  status?: Maybe<ClaimStatus>;
  /** ISO-8601 */
  submissionDate?: Maybe<Scalars["String"]["output"]>;
  submitter?: Maybe<Submitter>;
};

export type ClaimEvent = {
  __typename?: "ClaimEvent";
  /** ISO-8601 */
  createdAt?: Maybe<Scalars["String"]["output"]>;
  createdBy?: Maybe<Scalars["String"]["output"]>;
  eventId?: Maybe<Scalars["String"]["output"]>;
  fromStatus?: Maybe<Scalars["String"]["output"]>;
  toStatus?: Maybe<Scalars["String"]["output"]>;
};

export type ClaimFilter = {
  claimRef?: InputMaybe<FilterItem_String_IdentifierOperationTypeInput>;
  id?: InputMaybe<FilterItem_String_IdentifierOperationTypeInput>;
  policyId?: InputMaybe<FilterItem_String_IdentifierOperationTypeInput>;
  status?: InputMaybe<FilterItem_ClaimStatus_IdentifierOperationTypeInput>;
};

export type ClaimPaging = {
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<ClaimSortBy>;
  sorting?: InputMaybe<SortingOrder>;
};

export type ClaimParams = {
  filter?: InputMaybe<ClaimFilter>;
  paging?: InputMaybe<ClaimPaging>;
};

export type ClaimRequest = {
  data?: InputMaybe<Scalars["String"]["input"]>;
  insured?: InputMaybe<PersonInput>;
  policyHolder?: InputMaybe<PersonInput>;
  policyId?: InputMaybe<Scalars["String"]["input"]>;
};

export enum ClaimSortBy {
  CreatedDate = "createdDate",
  SubmissionDate = "submissionDate"
}

export enum ClaimStatus {
  ClaimAccepted = "CLAIM_ACCEPTED",
  ClaimClosed = "CLAIM_CLOSED",
  ClaimCompleted = "CLAIM_COMPLETED",
  ClaimDraft = "CLAIM_DRAFT",
  ClaimRejected = "CLAIM_REJECTED",
  ClaimSubmitted = "CLAIM_SUBMITTED",
  ItemInspectionComplete = "ITEM_INSPECTION_COMPLETE",
  PaymentCollected = "PAYMENT_COLLECTED",
  PaymentPending = "PAYMENT_PENDING",
  PaymentRejected = "PAYMENT_REJECTED"
}

export type CreateTheirAccountingNoteRqInput = {
  insurletId?: InputMaybe<Scalars["String"]["input"]>;
  /** ISO-8601 */
  to?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CreateTheirAccountingNoteRs = {
  __typename?: "CreateTheirAccountingNoteRs";
  accountingNoteId?: Maybe<Scalars["String"]["output"]>;
};

export type CyberAsset = Asset & {
  __typename?: "CyberAsset";
  /** attachments */
  attachments?: Maybe<Array<Maybe<AttachmentQl>>>;
  blackList?: Maybe<CyberItem>;
  currency?: Maybe<Scalars["String"]["output"]>;
  cyberInsights?: Maybe<CyberItem>;
  darkWeb?: Maybe<CyberItem>;
  description?: Maybe<Scalars["String"]["output"]>;
  identifier?: Maybe<Scalars["String"]["output"]>;
  marketValue?: Maybe<Scalars["Float"]["output"]>;
  metadata?: Maybe<Scalars["String"]["output"]>;
  /** state */
  state?: Maybe<ExternalState>;
  tripleCheck?: Maybe<CyberItem>;
  type?: Maybe<AssetType>;
  vulnerabilityScan?: Maybe<CyberItem>;
};

export type CyberAssetInput = {
  /** attachments */
  attachments?: InputMaybe<Array<InputMaybe<AttachmentQlInput>>>;
  blackList?: InputMaybe<CyberItemInput>;
  /** The ISO 4217 symbol of the currency. */
  currency?: InputMaybe<Scalars["String"]["input"]>;
  cyberInsights?: InputMaybe<CyberItemInput>;
  darkWeb?: InputMaybe<CyberItemInput>;
  /** description */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** identifier */
  identifier?: InputMaybe<Scalars["String"]["input"]>;
  /** marketValue */
  marketValue?: InputMaybe<Scalars["Float"]["input"]>;
  /** metadata */
  metadata?: InputMaybe<Scalars["String"]["input"]>;
  /** state */
  state?: InputMaybe<ExternalState>;
  tripleCheck?: InputMaybe<CyberItemInput>;
  /** type */
  type?: InputMaybe<AssetType>;
  vulnerabilityScan?: InputMaybe<CyberItemInput>;
};

export type CyberItem = {
  __typename?: "CyberItem";
  /** digitalProperties */
  digitalProperties?: Maybe<Array<Maybe<DigitalProperty>>>;
  /** state */
  state?: Maybe<InternalState>;
};

export type CyberItemInput = {
  /** digitalProperties */
  digitalProperties?: InputMaybe<Array<InputMaybe<DigitalPropertyInput>>>;
  /** state */
  state?: InputMaybe<InternalState>;
};

export type Dates = {
  __typename?: "Dates";
  /** ISO-8601 */
  cancelDate?: Maybe<Scalars["String"]["output"]>;
  /** ISO-8601 */
  expirationDate?: Maybe<Scalars["String"]["output"]>;
  /** ISO-8601 */
  issueDate?: Maybe<Scalars["String"]["output"]>;
  /** ISO-8601 */
  startDate?: Maybe<Scalars["String"]["output"]>;
};

export type DigitalProperty = {
  __typename?: "DigitalProperty";
  /** elementType */
  elementType?: Maybe<ElementType>;
  /** token */
  token?: Maybe<Scalars["String"]["output"]>;
  /** value */
  value?: Maybe<Scalars["String"]["output"]>;
  /** verificationMethod */
  verificationMethod?: Maybe<VerificationMethod>;
};

export type DigitalPropertyInput = {
  /** elementType */
  elementType?: InputMaybe<ElementType>;
  /** token */
  token?: InputMaybe<Scalars["String"]["input"]>;
  /** value */
  value?: InputMaybe<Scalars["String"]["input"]>;
  /** verificationMethod */
  verificationMethod?: InputMaybe<VerificationMethod>;
};

export type Distributor = {
  __typename?: "Distributor";
  active?: Maybe<Scalars["Boolean"]["output"]>;
  audit?: Maybe<Audit>;
  channels?: Maybe<Array<Maybe<Channel>>>;
  /** The id of the distributor */
  id?: Maybe<Scalars["String"]["output"]>;
  /** An internal reference for the distributor's name */
  internalRef?: Maybe<Scalars["String"]["output"]>;
  locales?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  market?: Maybe<Market>;
  /** The name of the distributor */
  name?: Maybe<Scalars["String"]["output"]>;
  /** The type of the distributor e.g. BANK,ECOMMERCE etc. */
  type?: Maybe<DistributorType>;
};

export type DistributorFilter = {
  active?: InputMaybe<FilterItem_Boolean_IdentifierOperationTypeInput>;
  id?: InputMaybe<FilterItem_String_IdentifierOperationTypeInput>;
  name?: InputMaybe<FilterItem_String_IdentifierOperationTypeInput>;
  refKey?: InputMaybe<FilterItem_String_IdentifierOperationTypeInput>;
  refKeys?: InputMaybe<FilterItem_String_ArrayOperationTypeInput>;
};

export type DistributorPaging = {
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<DistributorSortBy>;
  sorting?: InputMaybe<SortingOrder>;
};

export type DistributorParams = {
  filter?: InputMaybe<DistributorFilter>;
  paging?: InputMaybe<DistributorPaging>;
};

export enum DistributorSortBy {
  Id = "id"
}

export enum DistributorType {
  Bank = "BANK",
  BookingEngine = "BOOKING_ENGINE",
  Ecommerce = "ECOMMERCE",
  Hotel = "HOTEL",
  Telco = "TELCO"
}

export type Doc = {
  __typename?: "Doc";
  category?: Maybe<Scalars["String"]["output"]>;
  location: Location;
  name: Scalars["String"]["output"];
  provided?: Maybe<Scalars["Boolean"]["output"]>;
  static?: Maybe<Scalars["Boolean"]["output"]>;
  type?: Maybe<DocType>;
};

export enum DocType {
  Html = "HTML",
  Pdf = "PDF",
  Word = "WORD"
}

export type DocumentSpec = {
  __typename?: "DocumentSpec";
  /** Free text to describe the category of the document */
  category?: Maybe<Scalars["String"]["output"]>;
  /** Specifies if the document should not be injected to policy */
  excludeFromPolicy?: Maybe<Scalars["Boolean"]["output"]>;
  /** LocationSpec */
  locationSpec?: Maybe<Array<Maybe<LocationSpec>>>;
  /** If set to true this document is mandatory for policy to transmit to status ACCEPTED */
  mandatoryForAcceptedStatus?: Maybe<Scalars["Boolean"]["output"]>;
  /** The name of the document */
  name?: Maybe<Scalars["String"]["output"]>;
  /** boolean to either create the document post policy if not provided, or do not create it if provided */
  provided?: Maybe<Scalars["Boolean"]["output"]>;
  /** Specifies if the document is public information */
  public?: Maybe<Scalars["Boolean"]["output"]>;
  /** Does the document contain placeholders (dynamic) or is it the same for all policyholders (static) ? */
  static?: Maybe<Scalars["Boolean"]["output"]>;
  /** HTML, PDF etc */
  type?: Maybe<DocType>;
};

export enum ElementType {
  Domain = "DOMAIN",
  Email = "EMAIL",
  Url = "URL"
}

export type Entry_String_Float = {
  __typename?: "Entry_String_Float";
  key?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

export type Entry_String_String = {
  __typename?: "Entry_String_String";
  key?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type ExportTemplate = {
  __typename?: "ExportTemplate";
  ftlFile?: Maybe<Scalars["String"]["output"]>;
  templateName?: Maybe<Scalars["String"]["output"]>;
};

export enum ExternalState {
  Accepted = "ACCEPTED",
  Activated = "ACTIVATED",
  Expired = "EXPIRED",
  PendingReview = "PENDING_REVIEW"
}

export type Fees = {
  __typename?: "Fees";
  coDistributor?: Maybe<Scalars["Float"]["output"]>;
  distributor?: Maybe<Scalars["Float"]["output"]>;
  insurer?: Maybe<Scalars["Float"]["output"]>;
  wallbid?: Maybe<Scalars["Float"]["output"]>;
};

export enum FileType {
  Image = "IMAGE",
  Pdf = "PDF"
}

export type FilterItem_AccountingNoteStatus_IdentifierOperationTypeInput = {
  elements?: InputMaybe<Array<InputMaybe<AccountingNoteStatus>>>;
  operation?: InputMaybe<IdentifierOperationType>;
};

export type FilterItem_AccountingNoteType_IdentifierOperationTypeInput = {
  elements?: InputMaybe<Array<InputMaybe<AccountingNoteType>>>;
  operation?: InputMaybe<IdentifierOperationType>;
};

export type FilterItem_Boolean_IdentifierOperationTypeInput = {
  elements?: InputMaybe<Array<InputMaybe<Scalars["Boolean"]["input"]>>>;
  operation?: InputMaybe<IdentifierOperationType>;
};

export type FilterItem_ClaimStatus_IdentifierOperationTypeInput = {
  elements?: InputMaybe<Array<InputMaybe<ClaimStatus>>>;
  operation?: InputMaybe<IdentifierOperationType>;
};

export type FilterItem_DateTime_ScalarOperationTypeInput = {
  elements?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  operation?: InputMaybe<ScalarOperationType>;
};

export type FilterItem_Float_ScalarOperationTypeInput = {
  elements?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  operation?: InputMaybe<ScalarOperationType>;
};

export type FilterItem_InsurletType_IdentifierOperationTypeInput = {
  elements?: InputMaybe<Array<InputMaybe<InsurletType>>>;
  operation?: InputMaybe<IdentifierOperationType>;
};

export type FilterItem_PolicyStatusRt_IdentifierOperationTypeInput = {
  elements?: InputMaybe<Array<InputMaybe<PolicyStatusRt>>>;
  operation?: InputMaybe<IdentifierOperationType>;
};

export type FilterItem_PolicyStatus_IdentifierOperationTypeInput = {
  elements?: InputMaybe<Array<InputMaybe<PolicyStatus>>>;
  operation?: InputMaybe<IdentifierOperationType>;
};

export type FilterItem_String_ArrayOperationTypeInput = {
  elements?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  operation?: InputMaybe<ArrayOperationType>;
};

export type FilterItem_String_IdentifierOperationTypeInput = {
  elements?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  operation?: InputMaybe<IdentifierOperationType>;
};

export type FilterItem_String_ScalarOperationTypeInput = {
  elements?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  operation?: InputMaybe<ScalarOperationType>;
};

export type FilteredPolicyExportInput = {
  ftlFile?: InputMaybe<Scalars["String"]["input"]>;
  params?: InputMaybe<PolicyParams>;
  selectedTab?: InputMaybe<Scalars["String"]["input"]>;
  templateName?: InputMaybe<Scalars["String"]["input"]>;
};

export type FilteredPolicyExportResponse = {
  __typename?: "FilteredPolicyExportResponse";
  emailReference?: Maybe<Scalars["String"]["output"]>;
};

export type FinalizeTheirAccountingNoteRqInput = {
  invoiceNumber?: InputMaybe<Scalars["String"]["input"]>;
  /** ISO-8601 */
  issueDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type Format = {
  __typename?: "Format";
  dateFormat?: Maybe<Scalars["String"]["output"]>;
  decimalFormat?: Maybe<Scalars["String"]["output"]>;
  fields?: Maybe<Array<Maybe<Entry_String_String>>>;
};

export type FormatFieldsArgs = {
  key?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export enum Gender {
  B = "B",
  F = "F",
  M = "M",
  O = "O"
}

export type GlRange = {
  __typename?: "GlRange";
  /** ISO-8601 */
  fromDate?: Maybe<Scalars["String"]["output"]>;
  /** ISO-8601 */
  toDate?: Maybe<Scalars["String"]["output"]>;
};

export type HotelReservationAsset = Asset & {
  __typename?: "HotelReservationAsset";
  /** attachments */
  attachments?: Maybe<Array<Maybe<AttachmentQl>>>;
  /** beds */
  beds?: Maybe<Scalars["Int"]["output"]>;
  /** bookingDate (ISO-8601) */
  bookingDate?: Maybe<Scalars["String"]["output"]>;
  /** checkIn */
  checkIn?: Maybe<Scalars["String"]["output"]>;
  /** checkOut */
  checkOut?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  identifier?: Maybe<Scalars["String"]["output"]>;
  marketValue?: Maybe<Scalars["Float"]["output"]>;
  metadata?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<AssetType>;
  /** venueCode */
  venueCode?: Maybe<Scalars["String"]["output"]>;
  /** venueName */
  venueName?: Maybe<Scalars["String"]["output"]>;
};

export type HotelReservationAssetInput = {
  /** attachments */
  attachments?: InputMaybe<Array<InputMaybe<AttachmentQlInput>>>;
  /** beds */
  beds?: InputMaybe<Scalars["Int"]["input"]>;
  /** bookingDate (ISO-8601) */
  bookingDate?: InputMaybe<Scalars["String"]["input"]>;
  /** checkIn */
  checkIn?: InputMaybe<Scalars["String"]["input"]>;
  /** checkOut */
  checkOut?: InputMaybe<Scalars["String"]["input"]>;
  /** The ISO 4217 symbol of the currency. */
  currency?: InputMaybe<Scalars["String"]["input"]>;
  /** description */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** identifier */
  identifier?: InputMaybe<Scalars["String"]["input"]>;
  /** marketValue */
  marketValue?: InputMaybe<Scalars["Float"]["input"]>;
  /** metadata */
  metadata?: InputMaybe<Scalars["String"]["input"]>;
  /** type */
  type?: InputMaybe<AssetType>;
  /** venueCode */
  venueCode?: InputMaybe<Scalars["String"]["input"]>;
  /** venueName */
  venueName?: InputMaybe<Scalars["String"]["input"]>;
};

export enum IdentifierOperationType {
  Equals = "EQUALS",
  EqualsNotNull = "EQUALS_NOT_NULL",
  EqualsNull = "EQUALS_NULL",
  NotEquals = "NOT_EQUALS"
}

export type Insurer = {
  __typename?: "Insurer";
  active?: Maybe<Scalars["Boolean"]["output"]>;
  audit?: Maybe<Audit>;
  /** The id of the insurer */
  id?: Maybe<Scalars["String"]["output"]>;
  locales?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  market?: Maybe<Market>;
  /** The name of the insurer */
  name?: Maybe<Scalars["String"]["output"]>;
};

export type InsurerFilter = {
  active?: InputMaybe<FilterItem_Boolean_IdentifierOperationTypeInput>;
  id?: InputMaybe<FilterItem_String_IdentifierOperationTypeInput>;
  name?: InputMaybe<FilterItem_String_IdentifierOperationTypeInput>;
};

export type InsurerPaging = {
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<InsurerSortBy>;
  sorting?: InputMaybe<SortingOrder>;
};

export type InsurerParams = {
  filter?: InputMaybe<InsurerFilter>;
  paging?: InputMaybe<InsurerPaging>;
};

export enum InsurerSortBy {
  Id = "id",
  Name = "name"
}

export type Insurlet = {
  __typename?: "Insurlet";
  /** Is the insurlet active at the moment? */
  active?: Maybe<Scalars["Boolean"]["output"]>;
  /** Audit data of the insurlet */
  audit?: Maybe<Audit>;
  /** A short description of the insurlets context */
  description?: Maybe<Scalars["String"]["output"]>;
  distributors?: Maybe<Array<Maybe<Distributor>>>;
  /** Document data of the insurlet */
  documentSpecs?: Maybe<Array<Maybe<DocumentSpec>>>;
  /** The Insurlet Id */
  id?: Maybe<Scalars["String"]["output"]>;
  insurer?: Maybe<Insurer>;
  /** Path indicating the integration with insurer, if applicable */
  integrationPath?: Maybe<Scalars["String"]["output"]>;
  /** An internal reference for the insurlet's name */
  internalRef?: Maybe<Scalars["String"]["output"]>;
  /** Supported locales */
  locales?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** The name of the insurlet */
  name?: Maybe<Scalars["String"]["output"]>;
  partners?: Maybe<Array<Maybe<Partner>>>;
  /** The policy request schema */
  policySpecRequestSchema?: Maybe<Scalars["String"]["output"]>;
  /** Policy data of the insurlet */
  policySpecs?: Maybe<Array<Maybe<JsonSchemaProperty>>>;
  /** Quote data of the insurlet */
  quoteSpecs?: Maybe<Array<Maybe<QuoteSpec>>>;
  /** The quote request schema for each step */
  quoteSpecsRequestSchemas?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** The tags of the insurlet */
  tags?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** The type of the insurance eg HOME,CYBER,TRAVEL etc */
  type?: Maybe<InsurletType>;
  /** VersionInfo of the insurlet */
  versionInfo?: Maybe<VersionInfo>;
};

export type InsurletFilter = {
  active?: InputMaybe<FilterItem_Boolean_IdentifierOperationTypeInput>;
  coDistributor?: InputMaybe<FilterItem_String_ArrayOperationTypeInput>;
  /** ISO-8601 */
  dateCreated?: InputMaybe<FilterItem_DateTime_ScalarOperationTypeInput>;
  distributor?: InputMaybe<FilterItem_String_ArrayOperationTypeInput>;
  insurer?: InputMaybe<FilterItem_String_IdentifierOperationTypeInput>;
  name?: InputMaybe<FilterItem_String_IdentifierOperationTypeInput>;
  tags?: InputMaybe<FilterItem_String_ArrayOperationTypeInput>;
  type?: InputMaybe<FilterItem_InsurletType_IdentifierOperationTypeInput>;
};

export type InsurletPaging = {
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<InsurletSortBy>;
  sorting?: InputMaybe<SortingOrder>;
};

export type InsurletParams = {
  filter?: InputMaybe<InsurletFilter>;
  paging?: InputMaybe<InsurletPaging>;
};

export enum InsurletSortBy {
  Name = "name",
  Type = "type"
}

export enum InsurletType {
  Cyber = "CYBER",
  Device = "DEVICE",
  Home = "HOME",
  Life = "LIFE",
  Motor = "MOTOR",
  Travel = "TRAVEL"
}

export enum InternalState {
  Activated = "ACTIVATED",
  Expired = "EXPIRED",
  Ignored = "IGNORED",
  InputRequired = "INPUT_REQUIRED",
  PendingActivation = "PENDING_ACTIVATION"
}

export type JsonSchemaProperty = {
  __typename?: "JsonSchemaProperty";
  /** Field name */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Field type */
  type?: Maybe<Scalars["String"]["output"]>;
};

export type Location = {
  __typename?: "Location";
  docRef?: Maybe<Scalars["String"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
};

export type LocationSpec = {
  __typename?: "LocationSpec";
  docRef: Scalars["String"]["output"];
  format?: Maybe<Format>;
  locale?: Maybe<Scalars["String"]["output"]>;
};

export type Market = {
  __typename?: "Market";
  /** The id of the currency the market uses */
  currencyId?: Maybe<Scalars["String"]["output"]>;
  /** The id of the market */
  id?: Maybe<Scalars["String"]["output"]>;
  /** The name of the market */
  name?: Maybe<Scalars["String"]["output"]>;
};

export type Meta = {
  __typename?: "Meta";
  currentPage: Scalars["Int"]["output"];
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
  params?: Maybe<Scalars["String"]["output"]>;
  previousPageToken?: Maybe<Scalars["String"]["output"]>;
  totalPages?: Maybe<Scalars["Int"]["output"]>;
  totalRows?: Maybe<Scalars["Int"]["output"]>;
};

/** Mutation root */
export type Mutation = {
  __typename?: "Mutation";
  /** Create claim */
  createClaim?: Maybe<Claim>;
  /** Create claim with Bicycle asset */
  createClaimBicycle?: Maybe<Claim>;
  /** Create claim with Cyber asset */
  createClaimCyber?: Maybe<Claim>;
  /** Create claim with Hotel Reservation asset */
  createClaimHotelReservation?: Maybe<Claim>;
  /** Create claim with portable device asset */
  createClaimPortableDevice?: Maybe<Claim>;
  /** Create claim with Residence asset */
  createClaimResidence?: Maybe<Claim>;
  /** Create claim with Vehicle asset */
  createClaimVehicle?: Maybe<Claim>;
  /** Create their invoice notes */
  createTheirInvoiceAccountingNotes?: Maybe<CreateTheirAccountingNoteRs>;
  /** Create user */
  createUser?: Maybe<User>;
  /** Delete note */
  deleteTheirAccountingNote?: Maybe<Scalars["Void"]["output"]>;
  /** Delete user */
  deleteUser?: Maybe<Scalars["String"]["output"]>;
  /** Export policies given specified filters */
  filteredPolicyExport?: Maybe<FilteredPolicyExportResponse>;
  /** Finalize their invoice note */
  finalizeTheirInvoiceAccountingNote?: Maybe<AccountingNote>;
  /** Pay their invoice note */
  payTheirAccountingNote?: Maybe<AccountingNote>;
  /** Retry issuance */
  retryIssuance?: Maybe<Policy>;
  /** Submit claim */
  submitClaim?: Maybe<Claim>;
  /** Update claim */
  updateClaim?: Maybe<Claim>;
  /** Update claim with Bicycle asset */
  updateClaimBicycle?: Maybe<Claim>;
  /** Update claim with Hotel Reservation asset */
  updateClaimHotelReservation?: Maybe<Claim>;
  /** Update claim with portable device asset */
  updateClaimPortableDevice?: Maybe<Claim>;
  /** Update claim with Residence asset */
  updateClaimResidence?: Maybe<Claim>;
  /** Update claim with Vehicle asset */
  updateClaimVehicle?: Maybe<Claim>;
  /** Update user */
  updateUser?: Maybe<User>;
};

/** Mutation root */
export type MutationCreateClaimArgs = {
  claimRequest?: InputMaybe<ClaimRequest>;
};

/** Mutation root */
export type MutationCreateClaimBicycleArgs = {
  assets?: InputMaybe<Array<InputMaybe<BicycleAssetInput>>>;
  claimRequest?: InputMaybe<ClaimRequest>;
};

/** Mutation root */
export type MutationCreateClaimCyberArgs = {
  assets?: InputMaybe<Array<InputMaybe<CyberAssetInput>>>;
  claimRequest?: InputMaybe<ClaimRequest>;
};

/** Mutation root */
export type MutationCreateClaimHotelReservationArgs = {
  assets?: InputMaybe<Array<InputMaybe<HotelReservationAssetInput>>>;
  claimRequest?: InputMaybe<ClaimRequest>;
};

/** Mutation root */
export type MutationCreateClaimPortableDeviceArgs = {
  assets?: InputMaybe<Array<InputMaybe<PortableDeviceAssetInput>>>;
  claimRequest?: InputMaybe<ClaimRequest>;
};

/** Mutation root */
export type MutationCreateClaimResidenceArgs = {
  assets?: InputMaybe<Array<InputMaybe<ResidenceAssetInput>>>;
  claimRequest?: InputMaybe<ClaimRequest>;
};

/** Mutation root */
export type MutationCreateClaimVehicleArgs = {
  assets?: InputMaybe<Array<InputMaybe<VehicleAssetInput>>>;
  claimRequest?: InputMaybe<ClaimRequest>;
};

/** Mutation root */
export type MutationCreateTheirInvoiceAccountingNotesArgs = {
  createTheirAccountingNoteRq?: InputMaybe<CreateTheirAccountingNoteRqInput>;
};

/** Mutation root */
export type MutationCreateUserArgs = {
  user?: InputMaybe<UserInput>;
};

/** Mutation root */
export type MutationDeleteTheirAccountingNoteArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Mutation root */
export type MutationDeleteUserArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Mutation root */
export type MutationFilteredPolicyExportArgs = {
  filteredPolicyExportInput?: InputMaybe<FilteredPolicyExportInput>;
};

/** Mutation root */
export type MutationFinalizeTheirInvoiceAccountingNoteArgs = {
  finalizeTheirAccountingNoteRq?: InputMaybe<FinalizeTheirAccountingNoteRqInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Mutation root */
export type MutationPayTheirAccountingNoteArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  paidTheirAccountingNoteRq?: InputMaybe<PaidTheirAccountingNoteRqInput>;
};

/** Mutation root */
export type MutationRetryIssuanceArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Mutation root */
export type MutationSubmitClaimArgs = {
  claimId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Mutation root */
export type MutationUpdateClaimArgs = {
  claimId?: InputMaybe<Scalars["String"]["input"]>;
  claimRequest?: InputMaybe<ClaimRequest>;
};

/** Mutation root */
export type MutationUpdateClaimBicycleArgs = {
  assets?: InputMaybe<Array<InputMaybe<BicycleAssetInput>>>;
  claimId?: InputMaybe<Scalars["String"]["input"]>;
  claimRequest?: InputMaybe<ClaimRequest>;
};

/** Mutation root */
export type MutationUpdateClaimHotelReservationArgs = {
  assets?: InputMaybe<Array<InputMaybe<HotelReservationAssetInput>>>;
  claimId?: InputMaybe<Scalars["String"]["input"]>;
  claimRequest?: InputMaybe<ClaimRequest>;
};

/** Mutation root */
export type MutationUpdateClaimPortableDeviceArgs = {
  assets?: InputMaybe<Array<InputMaybe<PortableDeviceAssetInput>>>;
  claimId?: InputMaybe<Scalars["String"]["input"]>;
  claimRequest?: InputMaybe<ClaimRequest>;
};

/** Mutation root */
export type MutationUpdateClaimResidenceArgs = {
  assets?: InputMaybe<Array<InputMaybe<ResidenceAssetInput>>>;
  claimId?: InputMaybe<Scalars["String"]["input"]>;
  claimRequest?: InputMaybe<ClaimRequest>;
};

/** Mutation root */
export type MutationUpdateClaimVehicleArgs = {
  assets?: InputMaybe<Array<InputMaybe<VehicleAssetInput>>>;
  claimId?: InputMaybe<Scalars["String"]["input"]>;
  claimRequest?: InputMaybe<ClaimRequest>;
};

/** Mutation root */
export type MutationUpdateUserArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  user?: InputMaybe<UserInput>;
};

export type NoteFilter = {
  finalized?: InputMaybe<FilterItem_Boolean_IdentifierOperationTypeInput>;
  id?: InputMaybe<FilterItem_String_IdentifierOperationTypeInput>;
  invoiceNumber?: InputMaybe<FilterItem_String_ScalarOperationTypeInput>;
  noteType?: InputMaybe<FilterItem_AccountingNoteType_IdentifierOperationTypeInput>;
  status?: InputMaybe<FilterItem_AccountingNoteStatus_IdentifierOperationTypeInput>;
};

export type NotePaging = {
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<NoteSortBy>;
  sorting?: InputMaybe<SortingOrder>;
};

export type NoteParams = {
  filter?: InputMaybe<NoteFilter>;
  paging?: InputMaybe<NotePaging>;
};

export enum NoteSortBy {
  Id = "id",
  InvoiceNumber = "invoiceNumber"
}

export type PaidTheirAccountingNoteRqInput = {
  /** ISO-8601 */
  paidDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type Partner = {
  __typename?: "Partner";
  /** The coDistributors in partnership */
  coDistributors?: Maybe<Array<Maybe<Distributor>>>;
  /** The id of the main distributor */
  distributor?: Maybe<Distributor>;
};

export type Payment = {
  __typename?: "Payment";
  amount?: Maybe<Scalars["Float"]["output"]>;
  audit?: Maybe<Audit>;
  cardDetails?: Maybe<CardDetails>;
  currency?: Maybe<Scalars["String"]["output"]>;
  distributor?: Maybe<Distributor>;
  events?: Maybe<Array<Maybe<PaymentEvent>>>;
  id?: Maybe<Scalars["String"]["output"]>;
  insurer?: Maybe<Insurer>;
  /** ISO-8601 */
  paidDate?: Maybe<Scalars["String"]["output"]>;
  paymentMethod?: Maybe<Scalars["String"]["output"]>;
  policyIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  processingFee?: Maybe<Scalars["Float"]["output"]>;
  provider?: Maybe<PaymentProvider>;
  refundedAmount: Scalars["Float"]["output"];
  status?: Maybe<PaymentStatusType>;
  transactionRef?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentEvent = {
  __typename?: "PaymentEvent";
  /** ISO-8601 */
  eventCreated?: Maybe<Scalars["String"]["output"]>;
  eventRefId?: Maybe<Scalars["String"]["output"]>;
  eventType?: Maybe<PaymentEventType>;
};

export enum PaymentEventType {
  Init = "INIT",
  Link = "LINK",
  Payment = "PAYMENT",
  Refund = "REFUND"
}

export type PaymentProof = {
  __typename?: "PaymentProof";
  amount?: Maybe<Scalars["Float"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  external: Scalars["Boolean"]["output"];
  paymentProvider?: Maybe<PaymentProvider>;
  provider?: Maybe<Scalars["String"]["output"]>;
  /** ISO-8601 */
  time?: Maybe<Scalars["String"]["output"]>;
  transactionId?: Maybe<Scalars["String"]["output"]>;
};

export enum PaymentProvider {
  Eurolife = "EUROLIFE",
  ExternalCoDistributor = "EXTERNAL_CO_DISTRIBUTOR",
  ExternalDistributor = "EXTERNAL_DISTRIBUTOR",
  ExternalInsurer = "EXTERNAL_INSURER",
  Stripe = "STRIPE",
  Worldline = "WORLDLINE"
}

export enum PaymentStatusType {
  Cancelled = "CANCELLED",
  Created = "CREATED",
  FullyRefunded = "FULLY_REFUNDED",
  Paid = "PAID",
  PartiallyRefunded = "PARTIALLY_REFUNDED",
  Pending = "PENDING"
}

export type Person = {
  __typename?: "Person";
  /** addresses */
  addresses?: Maybe<Array<Maybe<PostalAddress>>>;
  /** attachments */
  attachments?: Maybe<Array<Maybe<AttachmentQl>>>;
  /** cellPhoneNo */
  cellPhoneNo?: Maybe<Scalars["String"]["output"]>;
  /** The birth country of the person. */
  countryOfBirth?: Maybe<Scalars["String"]["output"]>;
  /** dateOfBirth */
  dateOfBirth?: Maybe<Scalars["String"]["output"]>;
  /** email */
  email?: Maybe<Scalars["String"]["output"]>;
  /** The father's name of the person. */
  fathersName?: Maybe<Scalars["String"]["output"]>;
  /** firstName */
  firstName?: Maybe<Scalars["String"]["output"]>;
  /** firstNameLocal */
  firstNameLocal?: Maybe<Scalars["String"]["output"]>;
  /** fullName */
  fullName?: Maybe<Scalars["String"]["output"]>;
  /** fullNameLocal */
  fullNameLocal?: Maybe<Scalars["String"]["output"]>;
  /** gender */
  gender?: Maybe<Gender>;
  /** lastName */
  lastName?: Maybe<Scalars["String"]["output"]>;
  /** lastNameLocal */
  lastNameLocal?: Maybe<Scalars["String"]["output"]>;
  /** metadata */
  metadata?: Maybe<Scalars["String"]["output"]>;
  /** middleName */
  middleName?: Maybe<Scalars["String"]["output"]>;
  /** middleNameLocal */
  middleNameLocal?: Maybe<Scalars["String"]["output"]>;
  /** The national ID of the person */
  natId?: Maybe<Scalars["String"]["output"]>;
  /** nationality */
  nationality?: Maybe<Scalars["String"]["output"]>;
  /** passportCountry */
  passportCountry?: Maybe<Scalars["String"]["output"]>;
  /** The passport number of the person */
  passportId?: Maybe<Scalars["String"]["output"]>;
  /** profession */
  profession?: Maybe<Scalars["String"]["output"]>;
  /** The VAT ID / Tax ID of the person */
  vatId?: Maybe<Scalars["String"]["output"]>;
};

export type PersonInput = {
  /** addresses */
  addresses?: InputMaybe<Array<InputMaybe<PostalAddressInput>>>;
  /** attachments */
  attachments?: InputMaybe<Array<InputMaybe<AttachmentQlInput>>>;
  /** cellPhoneNo */
  cellPhoneNo?: InputMaybe<Scalars["String"]["input"]>;
  /** The birth country of the person. */
  countryOfBirth?: InputMaybe<Scalars["String"]["input"]>;
  /** dateOfBirth */
  dateOfBirth?: InputMaybe<Scalars["String"]["input"]>;
  /** email */
  email?: InputMaybe<Scalars["String"]["input"]>;
  /** The father's name of the person. */
  fathersName?: InputMaybe<Scalars["String"]["input"]>;
  /** firstName */
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  /** firstNameLocal */
  firstNameLocal?: InputMaybe<Scalars["String"]["input"]>;
  /** fullName */
  fullName?: InputMaybe<Scalars["String"]["input"]>;
  /** fullNameLocal */
  fullNameLocal?: InputMaybe<Scalars["String"]["input"]>;
  /** gender */
  gender?: InputMaybe<Gender>;
  /** lastName */
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  /** lastNameLocal */
  lastNameLocal?: InputMaybe<Scalars["String"]["input"]>;
  /** metadata */
  metadata?: InputMaybe<Scalars["String"]["input"]>;
  /** middleName */
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  /** middleNameLocal */
  middleNameLocal?: InputMaybe<Scalars["String"]["input"]>;
  /** The national ID of the person */
  natId?: InputMaybe<Scalars["String"]["input"]>;
  /** nationality */
  nationality?: InputMaybe<Scalars["String"]["input"]>;
  /** passportCountry */
  passportCountry?: InputMaybe<Scalars["String"]["input"]>;
  /** The passport number of the person */
  passportId?: InputMaybe<Scalars["String"]["input"]>;
  /** profession */
  profession?: InputMaybe<Scalars["String"]["input"]>;
  /** The VAT ID / Tax ID of the person */
  vatId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Policy = {
  __typename?: "Policy";
  abandonReason?: Maybe<AbandonReason>;
  anonymized?: Maybe<Scalars["Boolean"]["output"]>;
  assets?: Maybe<Array<Maybe<Asset>>>;
  audit?: Maybe<Audit>;
  cancellationEndorsement?: Maybe<CancellationEndorsement>;
  channel?: Maybe<Channel>;
  coDistributor?: Maybe<Distributor>;
  data?: Maybe<Scalars["String"]["output"]>;
  dates?: Maybe<Dates>;
  distributor?: Maybe<Distributor>;
  docs?: Maybe<Array<Maybe<Doc>>>;
  eligibilityRetry?: Maybe<RetryData>;
  events?: Maybe<Array<Maybe<PolicyEvent>>>;
  id?: Maybe<Scalars["String"]["output"]>;
  insurer?: Maybe<Insurer>;
  insurerData?: Maybe<Scalars["String"]["output"]>;
  insurlet?: Maybe<Insurlet>;
  otherInsured?: Maybe<Array<Maybe<Person>>>;
  payment?: Maybe<Payment>;
  paymentProof?: Maybe<PaymentProof>;
  policyHolder?: Maybe<Person>;
  policyRef?: Maybe<Scalars["String"]["output"]>;
  pricing?: Maybe<Pricing>;
  primaryInsured?: Maybe<Person>;
  quote?: Maybe<Quote>;
  status?: Maybe<PolicyStatus>;
  statusRT?: Maybe<PolicyStatusRt>;
};

export type PolicyEvent = {
  __typename?: "PolicyEvent";
  caseRef?: Maybe<Scalars["String"]["output"]>;
  chainId?: Maybe<Scalars["String"]["output"]>;
  /** ISO-8601 */
  createdAt?: Maybe<Scalars["String"]["output"]>;
  createdBy?: Maybe<Scalars["String"]["output"]>;
  data?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  eventId?: Maybe<Scalars["String"]["output"]>;
  processType?: Maybe<PolicyEventProcessType>;
  type?: Maybe<PolicyEventType>;
};

export enum PolicyEventProcessType {
  Amend = "AMEND",
  Application = "APPLICATION",
  Cancellation = "CANCELLATION",
  Claim = "CLAIM",
  Issuance = "ISSUANCE"
}

export enum PolicyEventType {
  AmendmentRevision = "AMENDMENT_REVISION",
  ApplicationAbandoned = "APPLICATION_ABANDONED",
  ApplicationRejected = "APPLICATION_REJECTED",
  CancellationAccepted = "CANCELLATION_ACCEPTED",
  CancellationRejected = "CANCELLATION_REJECTED",
  CancellationRequest = "CANCELLATION_REQUEST",
  ClaimRequest = "CLAIM_REQUEST",
  IssuanceCompleted = "ISSUANCE_COMPLETED",
  IssuancePaymentCompleted = "ISSUANCE_PAYMENT_COMPLETED",
  ProcessCompleted = "PROCESS_COMPLETED",
  RefundCompleted = "REFUND_COMPLETED"
}

export type PolicyFilter = {
  assetIdentifier?: InputMaybe<FilterItem_String_ArrayOperationTypeInput>;
  cellPhoneNo?: InputMaybe<FilterItem_String_ArrayOperationTypeInput>;
  channelId?: InputMaybe<FilterItem_String_IdentifierOperationTypeInput>;
  coDistributorId?: InputMaybe<FilterItem_String_IdentifierOperationTypeInput>;
  /** ISO-8601 */
  created?: InputMaybe<FilterItem_DateTime_ScalarOperationTypeInput>;
  distributorId?: InputMaybe<FilterItem_String_IdentifierOperationTypeInput>;
  email?: InputMaybe<FilterItem_String_ArrayOperationTypeInput>;
  grossPremium?: InputMaybe<FilterItem_Float_ScalarOperationTypeInput>;
  groupRef?: InputMaybe<FilterItem_String_ArrayOperationTypeInput>;
  id?: InputMaybe<FilterItem_String_IdentifierOperationTypeInput>;
  identificationNumber?: InputMaybe<FilterItem_String_ArrayOperationTypeInput>;
  insurerId?: InputMaybe<FilterItem_String_IdentifierOperationTypeInput>;
  insurletId?: InputMaybe<FilterItem_String_IdentifierOperationTypeInput>;
  /** ISO-8601 */
  issued?: InputMaybe<FilterItem_DateTime_ScalarOperationTypeInput>;
  lastName?: InputMaybe<FilterItem_String_ArrayOperationTypeInput>;
  /** ISO-8601 */
  modified?: InputMaybe<FilterItem_DateTime_ScalarOperationTypeInput>;
  policyRef?: InputMaybe<FilterItem_String_ScalarOperationTypeInput>;
  status?: InputMaybe<FilterItem_PolicyStatus_IdentifierOperationTypeInput>;
  statusRT?: InputMaybe<FilterItem_PolicyStatusRt_IdentifierOperationTypeInput>;
};

export type PolicyPaging = {
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<PolicySortBy>;
  sorting?: InputMaybe<SortingOrder>;
};

export type PolicyParams = {
  filter?: InputMaybe<PolicyFilter>;
  paging?: InputMaybe<PolicyPaging>;
};

export enum PolicySortBy {
  Status = "status"
}

export enum PolicyStatus {
  Abandoned = "ABANDONED",
  Accepted = "ACCEPTED",
  Acquired = "ACQUIRED",
  Acquiring = "ACQUIRING",
  Cancelled = "CANCELLED",
  Issued = "ISSUED",
  Paid = "PAID",
  Rejected = "REJECTED"
}

export enum PolicyStatusRt {
  Abandoned = "ABANDONED",
  Accepted = "ACCEPTED",
  Acquired = "ACQUIRED",
  Acquiring = "ACQUIRING",
  Cancelled = "CANCELLED",
  Expired = "EXPIRED",
  Future = "FUTURE",
  InEffect = "IN_EFFECT",
  Issued = "ISSUED",
  Paid = "PAID",
  Rejected = "REJECTED"
}

export type PortableDeviceAsset = Asset & {
  __typename?: "PortableDeviceAsset";
  /** activationDate (ISO-8601) */
  activationDate?: Maybe<Scalars["Date"]["output"]>;
  /** attachments */
  attachments?: Maybe<Array<Maybe<AttachmentQl>>>;
  currency?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  /** estimatedPurchaseDate (ISO-8601) */
  estimatedPurchaseDate?: Maybe<Scalars["Date"]["output"]>;
  identifier?: Maybe<Scalars["String"]["output"]>;
  /** imei */
  imei?: Maybe<Scalars["String"]["output"]>;
  /** manufacturer */
  manufacturer?: Maybe<Scalars["String"]["output"]>;
  marketValue?: Maybe<Scalars["Float"]["output"]>;
  metadata?: Maybe<Scalars["String"]["output"]>;
  /** model */
  model?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<AssetType>;
  /** version */
  version?: Maybe<Scalars["String"]["output"]>;
};

export type PortableDeviceAssetInput = {
  /** activationDate (ISO-8601) */
  activationDate?: InputMaybe<Scalars["Date"]["input"]>;
  /** attachments */
  attachments?: InputMaybe<Array<InputMaybe<AttachmentQlInput>>>;
  /** The ISO 4217 symbol of the currency. */
  currency?: InputMaybe<Scalars["String"]["input"]>;
  /** description */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** estimatedPurchaseDate (ISO-8601) */
  estimatedPurchaseDate?: InputMaybe<Scalars["Date"]["input"]>;
  /** identifier */
  identifier?: InputMaybe<Scalars["String"]["input"]>;
  /** imei */
  imei?: InputMaybe<Scalars["String"]["input"]>;
  /** manufacturer */
  manufacturer?: InputMaybe<Scalars["String"]["input"]>;
  /** marketValue */
  marketValue?: InputMaybe<Scalars["Float"]["input"]>;
  /** metadata */
  metadata?: InputMaybe<Scalars["String"]["input"]>;
  /** model */
  model?: InputMaybe<Scalars["String"]["input"]>;
  /** type */
  type?: InputMaybe<AssetType>;
  /** version */
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type PostalAddress = {
  __typename?: "PostalAddress";
  /** address1 */
  address1?: Maybe<Scalars["String"]["output"]>;
  /** address2 */
  address2?: Maybe<Scalars["String"]["output"]>;
  /** city */
  city?: Maybe<Scalars["String"]["output"]>;
  /** country */
  country?: Maybe<Scalars["String"]["output"]>;
  /** floor */
  floor?: Maybe<Scalars["Int"]["output"]>;
  /** phoneNo */
  phoneNo?: Maybe<Scalars["String"]["output"]>;
  /** postalCode */
  postalCode?: Maybe<Scalars["String"]["output"]>;
  /** propertyType */
  propertyType?: Maybe<PropertyType>;
  /** state */
  state?: Maybe<Scalars["String"]["output"]>;
  /** type */
  type?: Maybe<AddressType>;
};

export type PostalAddressInput = {
  /** address1 */
  address1?: InputMaybe<Scalars["String"]["input"]>;
  /** address2 */
  address2?: InputMaybe<Scalars["String"]["input"]>;
  /** city */
  city?: InputMaybe<Scalars["String"]["input"]>;
  /** country */
  country?: InputMaybe<Scalars["String"]["input"]>;
  /** floor */
  floor?: InputMaybe<Scalars["Int"]["input"]>;
  /** phoneNo */
  phoneNo?: InputMaybe<Scalars["String"]["input"]>;
  /** postalCode */
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  /** propertyType */
  propertyType?: InputMaybe<PropertyType>;
  /** state */
  state?: InputMaybe<Scalars["String"]["input"]>;
  /** type */
  type?: InputMaybe<AddressType>;
};

export type Pricing = {
  __typename?: "Pricing";
  currency?: Maybe<Scalars["String"]["output"]>;
  extras?: Maybe<Array<Maybe<Entry_String_Float>>>;
  fees?: Maybe<Fees>;
  grossPremium?: Maybe<Scalars["Float"]["output"]>;
  netPremium?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
};

export type PricingExtrasArgs = {
  key?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export enum PropertyType {
  ApartmentBuilding = "APARTMENT_BUILDING",
  House = "HOUSE"
}

/** Query root */
export type Query = {
  __typename?: "Query";
  /** Get notes */
  findAccountingNotes?: Maybe<ResponseDataWrapper_AccountingNote>;
  /** Get claim */
  findClaimById?: Maybe<Claim>;
  /** Get claims */
  findClaims?: Maybe<ResponseDataWrapper_Claim>;
  /** Get distributor */
  findDistributor?: Maybe<Distributor>;
  /** Get distributors */
  findDistributors?: Maybe<ResponseDataWrapper_Distributor>;
  /** Get Gl Range */
  findEligibleRange?: Maybe<GlRange>;
  /** Get insurer */
  findInsurer?: Maybe<Insurer>;
  /** Get insurers */
  findInsurers?: Maybe<ResponseDataWrapper_Insurer>;
  /** Get insurlet */
  findInsurletById?: Maybe<Insurlet>;
  /** Get insurlets */
  findInsurlets?: Maybe<ResponseDataWrapper_Insurlet>;
  /** Get payment */
  findPaymentById?: Maybe<Payment>;
  /** Get policies */
  findPolicies?: Maybe<ResponseDataWrapper_Policy>;
  /** Get policy */
  findPolicyById?: Maybe<Policy>;
  /** Get subscription by id */
  findSubscriptionById?: Maybe<SubscriptionInfo>;
  /** Get subscription by name */
  findSubscriptionByName?: Maybe<SubscriptionInfo>;
  /** Get user by id */
  findUserById?: Maybe<User>;
  /** Get users */
  findUsers?: Maybe<Array<Maybe<User>>>;
  /** Get authorization for each endpoint */
  getAuthorization?: Maybe<Array<Maybe<AuthorizedResource>>>;
  /** Get the current logged in user */
  getCurrentUser?: Maybe<User>;
  /** Get the tenant info */
  getTenant?: Maybe<Tenant>;
};

/** Query root */
export type QueryFindAccountingNotesArgs = {
  pageToken?: InputMaybe<Scalars["String"]["input"]>;
  params?: InputMaybe<NoteParams>;
};

/** Query root */
export type QueryFindClaimByIdArgs = {
  claimId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Query root */
export type QueryFindClaimsArgs = {
  pageToken?: InputMaybe<Scalars["String"]["input"]>;
  params?: InputMaybe<ClaimParams>;
};

/** Query root */
export type QueryFindDistributorArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Query root */
export type QueryFindDistributorsArgs = {
  pageToken?: InputMaybe<Scalars["String"]["input"]>;
  params?: InputMaybe<DistributorParams>;
};

/** Query root */
export type QueryFindEligibleRangeArgs = {
  insurletId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Query root */
export type QueryFindInsurerArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Query root */
export type QueryFindInsurersArgs = {
  pageToken?: InputMaybe<Scalars["String"]["input"]>;
  params?: InputMaybe<InsurerParams>;
};

/** Query root */
export type QueryFindInsurletByIdArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Query root */
export type QueryFindInsurletsArgs = {
  pageToken?: InputMaybe<Scalars["String"]["input"]>;
  params?: InputMaybe<InsurletParams>;
};

/** Query root */
export type QueryFindPaymentByIdArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Query root */
export type QueryFindPoliciesArgs = {
  pageToken?: InputMaybe<Scalars["String"]["input"]>;
  params?: InputMaybe<PolicyParams>;
};

/** Query root */
export type QueryFindPolicyByIdArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Query root */
export type QueryFindSubscriptionByIdArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Query root */
export type QueryFindSubscriptionByNameArgs = {
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** Query root */
export type QueryFindUserByIdArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Quote = {
  __typename?: "Quote";
  audit?: Maybe<Audit>;
  channelId?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  dataIn?: Maybe<Scalars["String"]["output"]>;
  dataOut?: Maybe<Scalars["String"]["output"]>;
  finalQuote: Scalars["Boolean"]["output"];
  id?: Maybe<Scalars["String"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  stepId?: Maybe<Scalars["String"]["output"]>;
};

export type QuoteSpec = {
  __typename?: "QuoteSpec";
  /** Extra dynamic data needed for the quote */
  request?: Maybe<Array<Maybe<JsonSchemaProperty>>>;
  /** The quote step's source */
  source?: Maybe<Scalars["String"]["output"]>;
  /** The step the quote is currently under */
  stepId?: Maybe<Scalars["String"]["output"]>;
  /** The quote's time to live' */
  ttl?: Maybe<Scalars["String"]["output"]>;
  /** The type of the quote eg FIXED,API,EXCEL */
  type?: Maybe<QuoteSpecType>;
};

export enum QuoteSpecType {
  Api = "API",
  DocGeneration = "DOC_GENERATION",
  Excel = "EXCEL",
  Fixed = "FIXED",
  JsonFile = "JSON_FILE",
  PolicyBasedDoc = "POLICY_BASED_DOC",
  PrepareVerify = "PREPARE_VERIFY",
  Verify = "VERIFY"
}

export type ResidenceAsset = Asset & {
  __typename?: "ResidenceAsset";
  /** address */
  address?: Maybe<PostalAddress>;
  /** attachments */
  attachments?: Maybe<Array<Maybe<AttachmentQl>>>;
  currency?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  identifier?: Maybe<Scalars["String"]["output"]>;
  marketValue?: Maybe<Scalars["Float"]["output"]>;
  metadata?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<AssetType>;
};

export type ResidenceAssetInput = {
  /** address */
  address?: InputMaybe<PostalAddressInput>;
  /** attachments */
  attachments?: InputMaybe<Array<InputMaybe<AttachmentQlInput>>>;
  /** The ISO 4217 symbol of the currency. */
  currency?: InputMaybe<Scalars["String"]["input"]>;
  /** description */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** identifier */
  identifier?: InputMaybe<Scalars["String"]["input"]>;
  /** marketValue */
  marketValue?: InputMaybe<Scalars["Float"]["input"]>;
  /** metadata */
  metadata?: InputMaybe<Scalars["String"]["input"]>;
  /** type */
  type?: InputMaybe<AssetType>;
};

export type ResponseDataWrapper_AccountingNote = {
  __typename?: "ResponseDataWrapper_AccountingNote";
  data?: Maybe<Array<Maybe<AccountingNote>>>;
  meta?: Maybe<Meta>;
};

export type ResponseDataWrapper_Claim = {
  __typename?: "ResponseDataWrapper_Claim";
  data?: Maybe<Array<Maybe<Claim>>>;
  meta?: Maybe<Meta>;
};

export type ResponseDataWrapper_Distributor = {
  __typename?: "ResponseDataWrapper_Distributor";
  data?: Maybe<Array<Maybe<Distributor>>>;
  meta?: Maybe<Meta>;
};

export type ResponseDataWrapper_Insurer = {
  __typename?: "ResponseDataWrapper_Insurer";
  data?: Maybe<Array<Maybe<Insurer>>>;
  meta?: Maybe<Meta>;
};

export type ResponseDataWrapper_Insurlet = {
  __typename?: "ResponseDataWrapper_Insurlet";
  data?: Maybe<Array<Maybe<Insurlet>>>;
  meta?: Maybe<Meta>;
};

export type ResponseDataWrapper_Policy = {
  __typename?: "ResponseDataWrapper_Policy";
  data?: Maybe<Array<Maybe<Policy>>>;
  meta?: Maybe<Meta>;
};

export type RetryData = {
  __typename?: "RetryData";
  autoRetryEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  eligible: Scalars["Boolean"]["output"];
  /** ISO-8601 */
  expirationDate?: Maybe<Scalars["String"]["output"]>;
  /** ISO-8601 */
  retriedDates?: Maybe<Array<Maybe<Scalars["DateTime"]["output"]>>>;
};

export enum ScalarOperationType {
  Between = "BETWEEN",
  Equals = "EQUALS",
  EqualsNotNull = "EQUALS_NOT_NULL",
  EqualsNull = "EQUALS_NULL",
  GreaterEqualsThan = "GREATER_EQUALS_THAN",
  GreaterThan = "GREATER_THAN",
  LessEqualsThan = "LESS_EQUALS_THAN",
  LessThan = "LESS_THAN",
  NotEquals = "NOT_EQUALS"
}

export enum SortingOrder {
  Ascending = "ASCENDING",
  Descending = "DESCENDING"
}

export type Submitter = {
  __typename?: "Submitter";
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  username?: Maybe<Scalars["String"]["output"]>;
};

export type SubscriptionInfo = {
  __typename?: "SubscriptionInfo";
  /** Audit data for the subscription */
  audit?: Maybe<Audit>;
  /** The Subscription Id */
  id?: Maybe<Scalars["String"]["output"]>;
  /** The name of the subcription */
  name?: Maybe<Scalars["String"]["output"]>;
  tenants?: Maybe<Array<Maybe<TenantInfo>>>;
};

export type Tenant = {
  __typename?: "Tenant";
  /** Audit data for the tenant */
  audit?: Maybe<Audit>;
  /** The tenant specific export templates */
  exportTemplates?: Maybe<Array<Maybe<ExportTemplate>>>;
  /** The Tenant Id */
  id?: Maybe<Scalars["String"]["output"]>;
  /** The market of the tenant */
  marketId?: Maybe<Scalars["String"]["output"]>;
  /** The name of the tenant */
  name?: Maybe<Scalars["String"]["output"]>;
  subscription?: Maybe<SubscriptionInfo>;
  /** Insurer/Distributor */
  type?: Maybe<TenantType>;
};

export type TenantInfo = {
  __typename?: "TenantInfo";
  hasAccess?: Maybe<Scalars["Boolean"]["output"]>;
  /** The Tenant Id */
  id?: Maybe<Scalars["String"]["output"]>;
  /** The name of the tenant */
  name?: Maybe<Scalars["String"]["output"]>;
  signedIn?: Maybe<Scalars["Boolean"]["output"]>;
  /** Insurer/Distributor */
  type?: Maybe<TenantType>;
};

export enum TenantType {
  Distributor = "DISTRIBUTOR",
  Insurer = "INSURER",
  Subscription = "SUBSCRIPTION",
  System = "SYSTEM"
}

export type User = {
  __typename?: "User";
  email?: Maybe<Scalars["String"]["output"]>;
  emailVerified?: Maybe<Scalars["Boolean"]["output"]>;
  enabled?: Maybe<Scalars["Boolean"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  oidcServerUrl?: Maybe<Scalars["String"]["output"]>;
  userRoles?: Maybe<Array<Maybe<UserRole>>>;
  username?: Maybe<Scalars["String"]["output"]>;
};

export type UserInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  emailVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  userRoles?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type UserRole = {
  __typename?: "UserRole";
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type VehicleAsset = Asset & {
  __typename?: "VehicleAsset";
  /** attachments */
  attachments?: Maybe<Array<Maybe<AttachmentQl>>>;
  /** chassisNo */
  chassisNo?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  /** dayPurchased */
  dayPurchased?: Maybe<Scalars["Int"]["output"]>;
  /** dayRegistered */
  dayRegistered?: Maybe<Scalars["Int"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  /** engineCapacity */
  engineCapacity?: Maybe<Scalars["Int"]["output"]>;
  /** engineCapacityUnit */
  engineCapacityUnit?: Maybe<Scalars["String"]["output"]>;
  /** engineType */
  engineType?: Maybe<Scalars["String"]["output"]>;
  identifier?: Maybe<Scalars["String"]["output"]>;
  /** inWarranty */
  inWarranty?: Maybe<Scalars["Boolean"]["output"]>;
  /** manufacturer */
  manufacturer?: Maybe<Scalars["String"]["output"]>;
  marketValue?: Maybe<Scalars["Float"]["output"]>;
  metadata?: Maybe<Scalars["String"]["output"]>;
  /** mileage */
  mileage?: Maybe<Scalars["Int"]["output"]>;
  /** mileageUnit */
  mileageUnit?: Maybe<Scalars["String"]["output"]>;
  /** model */
  model?: Maybe<Scalars["String"]["output"]>;
  /** modelYear */
  modelYear?: Maybe<Scalars["Int"]["output"]>;
  /** monthPurchased */
  monthPurchased?: Maybe<Scalars["Int"]["output"]>;
  /** monthRegistered */
  monthRegistered?: Maybe<Scalars["Int"]["output"]>;
  /** plateNo */
  plateNo?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<AssetType>;
  /** vehicleDealer */
  vehicleDealer?: Maybe<Scalars["String"]["output"]>;
  /** version */
  version?: Maybe<Scalars["String"]["output"]>;
  /** yearPurchased */
  yearPurchased?: Maybe<Scalars["Int"]["output"]>;
  /** yearRegistered */
  yearRegistered?: Maybe<Scalars["Int"]["output"]>;
};

export type VehicleAssetInput = {
  /** attachments */
  attachments?: InputMaybe<Array<InputMaybe<AttachmentQlInput>>>;
  /** chassisNo */
  chassisNo?: InputMaybe<Scalars["String"]["input"]>;
  /** The ISO 4217 symbol of the currency. */
  currency?: InputMaybe<Scalars["String"]["input"]>;
  /** dayPurchased */
  dayPurchased?: InputMaybe<Scalars["Int"]["input"]>;
  /** dayRegistered */
  dayRegistered?: InputMaybe<Scalars["Int"]["input"]>;
  /** description */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** engineCapacity */
  engineCapacity?: InputMaybe<Scalars["Int"]["input"]>;
  /** engineCapacityUnit */
  engineCapacityUnit?: InputMaybe<Scalars["String"]["input"]>;
  /** engineType */
  engineType?: InputMaybe<Scalars["String"]["input"]>;
  /** identifier */
  identifier?: InputMaybe<Scalars["String"]["input"]>;
  /** inWarranty */
  inWarranty?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** manufacturer */
  manufacturer?: InputMaybe<Scalars["String"]["input"]>;
  /** marketValue */
  marketValue?: InputMaybe<Scalars["Float"]["input"]>;
  /** metadata */
  metadata?: InputMaybe<Scalars["String"]["input"]>;
  /** mileage */
  mileage?: InputMaybe<Scalars["Int"]["input"]>;
  /** mileageUnit */
  mileageUnit?: InputMaybe<Scalars["String"]["input"]>;
  /** model */
  model?: InputMaybe<Scalars["String"]["input"]>;
  /** modelYear */
  modelYear?: InputMaybe<Scalars["Int"]["input"]>;
  /** monthPurchased */
  monthPurchased?: InputMaybe<Scalars["Int"]["input"]>;
  /** monthRegistered */
  monthRegistered?: InputMaybe<Scalars["Int"]["input"]>;
  /** plateNo */
  plateNo?: InputMaybe<Scalars["String"]["input"]>;
  /** type */
  type?: InputMaybe<AssetType>;
  /** vehicleDealer */
  vehicleDealer?: InputMaybe<Scalars["String"]["input"]>;
  /** version */
  version?: InputMaybe<Scalars["String"]["input"]>;
  /** yearPurchased */
  yearPurchased?: InputMaybe<Scalars["Int"]["input"]>;
  /** yearRegistered */
  yearRegistered?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum VerificationMethod {
  Dns = "DNS",
  EmailOtp = "EMAIL_OTP",
  File = "FILE"
}

export type VersionInfo = {
  __typename?: "VersionInfo";
  /** True if it is the original copy of the insurlet */
  original?: Maybe<Scalars["Boolean"]["output"]>;
  /** The original id of the insurlet */
  originalId?: Maybe<Scalars["String"]["output"]>;
  /** The version of the record */
  recordVersion?: Maybe<Scalars["Int"]["output"]>;
  /** The version id of the insurlet */
  versionId?: Maybe<Scalars["String"]["output"]>;
};
