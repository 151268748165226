<template>
  <div class="flex">
    <div
      class="badge badge-sm rounded-md py-2.5 px-2"
      :class="getColorByStatus(value!)"
    >
      <span class="text-nowrap">{{ useFormatUnderscoreText(value) }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ClaimStatus } from "~/graphql";

  defineProps<{
    value?: string;
  }>();

  const getColorByStatus = (value: string) => {
    let color = "";
    switch (value) {
      case ClaimStatus.ClaimAccepted:
        color = "badge-success";
        break;
      case ClaimStatus.ClaimRejected:
        color = "badge-error bg-[#FED4D9]";
        break;
      case ClaimStatus.ClaimSubmitted:
        color = "badge-base-300 bg-base-300";
        break;
      case ClaimStatus.ClaimDraft:
        color = "badge-info bg-[#DDEAFD]";
        break;
      default:
        color = "badge-success";
        break;
    }
    return color;
  };
</script>
