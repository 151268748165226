
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexCaby9LRPK9Meta } from "/home/vsts/work/1/s/pages/[tenant]/applications/index.vue?macro=true";
import { default as indexPURJJ5h9fTMeta } from "/home/vsts/work/1/s/pages/[tenant]/billing/index.vue?macro=true";
import { default as _91claim_93fGtppliQ0IMeta } from "/home/vsts/work/1/s/pages/[tenant]/claims/[claim].vue?macro=true";
import { default as indexuRQAZlDVqhMeta } from "/home/vsts/work/1/s/pages/[tenant]/claims/index.vue?macro=true";
import { default as indexXfQfYA0cS0Meta } from "/home/vsts/work/1/s/pages/[tenant]/dashboard/index.vue?macro=true";
import { default as _91doc_93EkJihkZjuQMeta } from "/home/vsts/work/1/s/pages/[tenant]/developers/docs/[doc].vue?macro=true";
import { default as indext1YJqYPkGBMeta } from "/home/vsts/work/1/s/pages/[tenant]/developers/index.vue?macro=true";
import { default as _91insurlet_93b52pLwApMEMeta } from "/home/vsts/work/1/s/pages/[tenant]/developers/insurlets/[insurlet].vue?macro=true";
import { default as tbd__91insurlet_93yEwwd9UHsZMeta } from "/home/vsts/work/1/s/pages/[tenant]/developers/tbd_[insurlet].vue?macro=true";
import { default as _91distributor_938Ji7OOBsPaMeta } from "/home/vsts/work/1/s/pages/[tenant]/distributors/[distributor].vue?macro=true";
import { default as indexgAKH1XEYmbMeta } from "/home/vsts/work/1/s/pages/[tenant]/distributors/index.vue?macro=true";
import { default as indexE5XJ6IJOxkMeta } from "/home/vsts/work/1/s/pages/[tenant]/index.vue?macro=true";
import { default as _91insurer_93cxI6YNItBOMeta } from "/home/vsts/work/1/s/pages/[tenant]/insurers/[insurer].vue?macro=true";
import { default as index2vuiov8xbEMeta } from "/home/vsts/work/1/s/pages/[tenant]/insurers/index.vue?macro=true";
import { default as _91insurlet_93mOkBLVKuJIMeta } from "/home/vsts/work/1/s/pages/[tenant]/insurlets/[insurlet].vue?macro=true";
import { default as indexVLhofNRPs4Meta } from "/home/vsts/work/1/s/pages/[tenant]/insurlets/index.vue?macro=true";
import { default as _91policy_934IW4BGB5kuMeta } from "/home/vsts/work/1/s/pages/[tenant]/policies/[policy].vue?macro=true";
import { default as indextGdwXznKI5Meta } from "/home/vsts/work/1/s/pages/[tenant]/policies/index.vue?macro=true";
import { default as newvEYx3BgvSwMeta } from "/home/vsts/work/1/s/pages/[tenant]/policies/new.vue?macro=true";
import { default as indexfFbMkzc6VdMeta } from "/home/vsts/work/1/s/pages/[tenant]/portfolio/index.vue?macro=true";
import { default as index46YP7V7BItMeta } from "/home/vsts/work/1/s/pages/[tenant]/retailers/applications/index.vue?macro=true";
import { default as portfolio3pGcYKLMSOMeta } from "/home/vsts/work/1/s/pages/[tenant]/retailers/portfolio.vue?macro=true";
import { default as subscriptionDuBvnUGlVyMeta } from "/home/vsts/work/1/s/pages/[tenant]/subscription.vue?macro=true";
import { default as indexegOWm4p6gtMeta } from "/home/vsts/work/1/s/pages/index.vue?macro=true";
export default [
  {
    name: "tenant-applications",
    path: "/:tenant()/applications",
    meta: indexCaby9LRPK9Meta || {},
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/applications/index.vue")
  },
  {
    name: "tenant-billing",
    path: "/:tenant()/billing",
    meta: indexPURJJ5h9fTMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/billing/index.vue")
  },
  {
    name: "tenant-claims-claim",
    path: "/:tenant()/claims/:claim()",
    meta: _91claim_93fGtppliQ0IMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/claims/[claim].vue")
  },
  {
    name: "tenant-claims",
    path: "/:tenant()/claims",
    meta: indexuRQAZlDVqhMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/claims/index.vue")
  },
  {
    name: "tenant-dashboard",
    path: "/:tenant()/dashboard",
    meta: indexXfQfYA0cS0Meta || {},
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/dashboard/index.vue")
  },
  {
    name: "tenant-developers-docs-doc",
    path: "/:tenant()/developers/docs/:doc()",
    meta: _91doc_93EkJihkZjuQMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/developers/docs/[doc].vue")
  },
  {
    name: "tenant-developers",
    path: "/:tenant()/developers",
    meta: indext1YJqYPkGBMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/developers/index.vue")
  },
  {
    name: "tenant-developers-insurlets-insurlet",
    path: "/:tenant()/developers/insurlets/:insurlet()",
    meta: _91insurlet_93b52pLwApMEMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/developers/insurlets/[insurlet].vue")
  },
  {
    name: "tenant-developers-tbd_insurlet",
    path: "/:tenant()/developers/tbd_:insurlet()",
    meta: tbd__91insurlet_93yEwwd9UHsZMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/developers/tbd_[insurlet].vue")
  },
  {
    name: "tenant-distributors-distributor",
    path: "/:tenant()/distributors/:distributor()",
    meta: _91distributor_938Ji7OOBsPaMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/distributors/[distributor].vue")
  },
  {
    name: "tenant-distributors",
    path: "/:tenant()/distributors",
    meta: indexgAKH1XEYmbMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/distributors/index.vue")
  },
  {
    name: "tenant",
    path: "/:tenant()",
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/index.vue")
  },
  {
    name: "tenant-insurers-insurer",
    path: "/:tenant()/insurers/:insurer()",
    meta: _91insurer_93cxI6YNItBOMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/insurers/[insurer].vue")
  },
  {
    name: "tenant-insurers",
    path: "/:tenant()/insurers",
    meta: index2vuiov8xbEMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/insurers/index.vue")
  },
  {
    name: "tenant-insurlets-insurlet",
    path: "/:tenant()/insurlets/:insurlet()",
    meta: _91insurlet_93mOkBLVKuJIMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/insurlets/[insurlet].vue")
  },
  {
    name: "tenant-insurlets",
    path: "/:tenant()/insurlets",
    meta: indexVLhofNRPs4Meta || {},
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/insurlets/index.vue")
  },
  {
    name: "tenant-policies-policy",
    path: "/:tenant()/policies/:policy()",
    meta: _91policy_934IW4BGB5kuMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/policies/[policy].vue")
  },
  {
    name: "tenant-policies",
    path: "/:tenant()/policies",
    meta: indextGdwXznKI5Meta || {},
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/policies/index.vue")
  },
  {
    name: "tenant-policies-new",
    path: "/:tenant()/policies/new",
    meta: newvEYx3BgvSwMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/policies/new.vue")
  },
  {
    name: "tenant-portfolio",
    path: "/:tenant()/portfolio",
    meta: indexfFbMkzc6VdMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/portfolio/index.vue")
  },
  {
    name: "tenant-retailers-applications",
    path: "/:tenant()/retailers/applications",
    meta: index46YP7V7BItMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/retailers/applications/index.vue")
  },
  {
    name: "tenant-retailers-portfolio",
    path: "/:tenant()/retailers/portfolio",
    meta: portfolio3pGcYKLMSOMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/retailers/portfolio.vue")
  },
  {
    name: "tenant-subscription",
    path: "/:tenant()/subscription",
    component: () => import("/home/vsts/work/1/s/pages/[tenant]/subscription.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/vsts/work/1/s/pages/index.vue")
  }
]