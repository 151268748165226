import {
  type Develop,
  developEnvironments,
  getCurrentEnvironment
} from "~/utils";

export default defineNuxtRouteMiddleware(async (to, from) => {
  // const env = getCurrentEnvironment();
  const isProduction = useRuntimeConfig().public.wbIsProduction;

  const restrictedURLs = [
    "tenant-billing",
    "tenant-claims",
    "tenant-applications",
    "tenant-dashboard",
    "tenant-portfolio",
    "tenant-retailers-portfolio",
    "tenant-retailers-applications"
  ];
  if (
    restrictedURLs.includes(to.name as string) &&
    typeof isProduction === "boolean" &&
    isProduction === true
  ) {
    return navigateTo({ name: "tenant-policies" });
  }

  // if (
  //   (to.name as string) === "tenant-new_applications" &&
  //   (!getTenantName("Attica Bank") || !getTenantName("Piraeus Bank"))
  // ) {
  //   return navigateTo({ name: "tenant-policies" });
  // }
});
