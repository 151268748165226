<template>
  <div class="flex gap-2 items-center font-normal min-w-0">
    {{ formattedDate }}
  </div>
</template>

<script lang="ts">
  export default {
    name: "WBClaimDate"
  };
</script>

<script setup lang="ts">
  import dayjs from "dayjs";
  import type { ViewValueProps } from "@/utils";

  const props = defineProps<ViewValueProps<string>>();

  const formattedDate = dayjs(props.value).format("MMM DD, YYYY");
</script>
